import React from "react"
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Layout from "layout/layout"
import SEO from "components/seo"
import OnlineResourceView from 'components/cards/OnlineResourceView'

const PagePadding = {
  padding: `0 0 32px 0`,
}

const ContentBlockStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  width: `100%`,
  padding: `0 0 40px 0`,
}

class OnlineResourcesPage extends React.Component {

  render() {
    const onlineResources = get(this, 'props.data.allContentfulOnlineResource.edges').map(edge => ( edge.node ))

    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-12" style={PagePadding}>
              <SEO title={`Online Resources`} />
              <h1>Online Resources</h1>
              <p>Online resources vary from design blogs, online courses, to one-off articles that I've found helpful.</p>
            </div>

            <div style={ContentBlockStyle}>
              {
                onlineResources.map(resource => (
                  <OnlineResourceView key={resource.id} onlineResource={resource} />
                ))
              }
            </div>

          </div>
        </div>
      </Layout>
    );
  }
}

export default OnlineResourcesPage

export const pageQuery = graphql`
  query OnlineResourcesQuery {
    allContentfulOnlineResource {
      edges {
        node {
          id
          resourceType
          title
          authors
          link
          deconstructingDesignSlug
          tags
        }
      }
    }
  }
`


